<template>
    <div>
        <notifications></notifications>
        <router-view :key="$route.fullPath" v-if="!show_login_prompt"></router-view>
        <!-- Login modal if not authenticated -->
        <modal class="show d-block" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm" v-if="!is_authed">
            <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="text-align: center" v-if="show_login_prompt">
                <h3>XSS Hunter <small><i>(<a target="_blank" href="https://docs.google.com/document/d/1HlkDOZhIxwgLWeFf4L8Vy3OIPAedeC3cMw0Jz4WORag/preview">Deprecated</a>)</i></small><br />
                    <i>Please login to continue.</i></h3>
                <base-input alternative v-model="username" type="username" placeholder="Username" autofocus v-on:keyup.enter="attempt_login" autofocus></base-input>
                <base-input alternative v-model="password" type="password" placeholder="Password" autofocus v-on:keyup.enter="attempt_login"></base-input>
                <center>
                    <vue-recaptcha sitekey="6Ld1v1UkAAAAAFI3vZXC3NSA2vylFQ82bEFouFwS" @verify="captcha_success" @expired="captcha_expired" @error="captcha_error"></vue-recaptcha>
                </center>
                <base-button block simple type="primary" v-on:click="attempt_login">
                    <i class="fas fa-key"></i> Authenticate
                </base-button>
                <base-alert v-if="invalid_password_used" class="mt-4" type="danger">
                    <i class="fas fa-times"></i> Incorrect password, try again.
                </base-alert>
            </card>
        </modal>
        <base-alert type="default" style="z-index: 9999" v-if="!is_authed">
            <strong>Abuse Complaint?</strong> For abuse reports about an XSS Hunter user, <a href="https://docs.google.com/forms/d/e/1FAIpQLSesDozRbgsfeTB4dCzrpd9cXTF8WmM18MzV3QTvNZqnBzKEDw/viewform" target="_blank">click here.</a>
        </base-alert>
        <div class="loading-bar" v-if="loading">
            <div class="progress">
                <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar bg-purp progress-bar-striped progress-bar-animated" style="width: 100%;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from "./components/Modal";
import BaseAlert from "./components/BaseAlert";
import api_request from '@/libs/api.js';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {
        Modal,
        BaseAlert,
        VueRecaptcha
    },
    data() {
        return {
            loading: false,
            is_authed: false,
            invalid_password_used: false,
            username: '',
            password: '',
            captcha_token: '',
        }
    },
    computed: {
        show_login_prompt() {
            return !this.is_authed;
        }
    },
    methods: {
        async captcha_error() {
            alert(`Error: An error occurred while processing the CAPTCHA`);
        },
        async captcha_expired() {
        },
        async captcha_success(token) {
            this.captcha_token = token;
        },
        async is_authenticated() {
            const auth_result = await api_request.is_authenticated();
            return auth_result.success;
        },
        async attempt_login() {
            const login_result = await api_request.authenticate(
                this.username,
                this.password,
                this.captcha_token
            );

            if (login_result.success) {
                this.is_authed = true;
                localStorage.setItem(
                    api_request.AUTH_TOKEN_LOCAL_STORAGE_KEY,
                    login_result.token
                );
                return
            }

            toastr.error(`That login doesn't look right, try a different password?`, 'Incorrect Login')
        },
        disableRTL() {
            if (!this.$rtl.isRTL) {
                this.$rtl.disableRTL();
            }
        },
        toggleNavOpen() {
            let root = document.getElementsByTagName('html')[0];
            root.classList.toggle('nav-open');
        },
    },
    async mounted() {
        this.loading = true;
        window.app = this;
        this.$watch('$route', this.disableRTL, { immediate: true });
        this.$watch('$sidebar.showSidebar', this.toggleNavOpen);

        this.is_authed = await this.is_authenticated();

        this.loading = false;
    }
};

function get_random_number_in_range(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}
</script>
<style>
.eye-white {
    color: #FFFFFF;
}

.eye-pink {
    color: #e14eca;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1rem 1rem;
}

.progress-bar {
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease;
}

.progress,
.progress-bar {
    display: flex;
    overflow: hidden;
}

.progress {
    height: 1rem;
    line-height: 0;
    font-size: .75rem;
    background-color: #1b0036;
    border-radius: .25rem;
}

.loading-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 16px;
    right: 0;
    z-index: 10;
}

.bg-purp {
    background-color: #8965e0 !important;
}
</style>