import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const Settings = () => import("@/pages/Settings.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/",
    children: [
      {
        path: "",
        name: "Account Settings",
        component: Settings
      }
    ]
  }
];

export default routes;
