const BASE_API_PATH = 'https://xsshunter.com';
const api_url_object = new URL(BASE_API_PATH);
const BASE_DOMAIN = api_url_object.host;
const AUTH_TOKEN_LOCAL_STORAGE_KEY = `AUTH_TOKEN`;

async function api_request(method, path, body) {
    const auth_token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    var request_options = {
        method: method,
        credentials: 'include',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Buster': 'true',
            'X-Auth-Token': auth_token,
        },
        redirect: 'follow'
    };
    if (body) {
        request_options.body = JSON.stringify(body);
    }
    window.app.loading = true;
    try {
      var response = await fetch(
          `${BASE_API_PATH}${path}`,
          request_options
      );
    } catch ( e ) {
      window.app.loading = false;
      throw e;
    }
    window.app.loading = false;
    const response_body = await response.json();

    return response_body;
}

async function is_authenticated() {
    return api_request(
        'GET',
        `/api/authed`,
        false
    );
}

async function authenticate(username, password, captcha_token) {
    return api_request(
        'POST',
        `/api/login`,
        {
            'username': username,
            'password': password,
            'captcha_token': captcha_token,
        }
    );
}

async function get_user_data() {
    return api_request(
        'GET',
        `/api/user`,
        false
    );
}

async function update_payload(payload) {
    return api_request(
        'POST',
        `/api/domain`,
        {
            'payload': payload
        }
    );
}

async function update_email(email) {
    return api_request(
        'POST',
        `/api/email`,
        {
            'email': email
        }
    );
}

async function update_password(password) {
    return api_request(
        'POST',
        `/api/password`,
        {
            'password': password
        }
    );
}

module.exports = {
    BASE_API_PATH,
    BASE_DOMAIN,
    api_request,
    is_authenticated,
    authenticate,
    get_user_data,
    update_payload,
    update_email,
    update_password,
    AUTH_TOKEN_LOCAL_STORAGE_KEY
}