<template>
    <div class="wrapper">
        <side-bar>
            <template slot="links">
                <sidebar-link to="/" name="Account Settings" icon="fas fa-cogs" />
                <li class="nav-item mt-5">
                  <a target="_blank" href="https://docs.google.com/document/d/1HlkDOZhIxwgLWeFf4L8Vy3OIPAedeC3cMw0Jz4WORag/preview" class="nav-link">
                    <i class="fas fa-file"></i>
                    <p>Deprecation FAQ</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://github.com/mandatoryprogrammer/xsshunter-express" class="nav-link">
                    <i class="tim-icons icon-app"></i>
                    <p>Run Your Own XSS Hunter</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://twitter.com/IAmMandatory" class="nav-link">
                    <i class="fab fa-twitter"></i>
                    <p>By @IAmMandatory</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" class="nav-link" v-on:click="logout">
                    <i class="tim-icons icon-button-power"></i>
                    <p>Logout</p>
                  </a>
                </li>
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>
            <dashboard-content @click.native="toggleSidebar">
            </dashboard-content>
            <content-footer></content-footer>
        </div>
    </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent,
        MobileMenu
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        logout() {
            localStorage.clear();
            window.location.reload();
        }
    }
};
</script>